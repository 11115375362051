import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"

import "../style/index.scss"

const NotFoundPage = () => (
  <Layout page="404">
    <div id="not-found-wrapper">
      <h1>Questa pagina non esiste :(</h1>
      <Link to="/">Vai alla pagina iniziale</Link>
    </div>
  </Layout>
)

export default NotFoundPage
